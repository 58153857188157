import { Alert, Snackbar, styled } from '@mui/material'
import React from 'react'

type AlertProps = {
  severity: 'success' | 'error' | 'info' | 'warning'
  message: string
  autoDismiss: number
  open: boolean
  handleClose: (_: React.SyntheticEvent | Event, reason?: string) => void
}

export default function SnackbarAlert({
  severity,
  message,
  autoDismiss,
  open,
  handleClose,
}: AlertProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoDismiss}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
