import Navbar from '@/common/components/Navbar'
import { Box, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Layout } from './styles/Layout.styles'
import { ReactComponent as Loading1 } from '@/assets/animations/loading/PolygonLoading1.svg'
import { ReactComponent as Loading2 } from '@/assets/animations/loading/PolygonLoading2.svg'
import { ReactComponent as Loading3 } from '@/assets/animations/loading/PolygonLoading3.svg'
import { ReactComponent as Loading4 } from '@/assets/animations/loading/PolygonLoading4.svg'
import { ReactComponent as Loading5 } from '@/assets/animations/loading/PolygonLoading5.svg'
import { ReactComponent as Loading6 } from '@/assets/animations/loading/PolygonLoading6.svg'
import { PageContainerLayout } from './styles/PageContainer.styles'

export default function LoadingLayout() {
  const [render, setRender] = useState([1])
  const renderAnimation = () => {
    setTimeout(() => {
      if (render.length > 5) {
        setRender([])
      } else {
        setRender([...render, render.length + 1])
      }
    }, 1000)
  }
  renderAnimation()
  return (
    <Layout>
      <Gradient />
      <PageContainerLayout>
        <Navbar showSignButton={false} />
        <Container>
          {render.map(item => {
            switch (item) {
              case 1:
                return <Loading1 className="loading1 animate" />
              case 2:
                return <Loading2 className="loading2 animate" />
              case 3:
                return <Loading3 className="loading3 animate" />
              case 4:
                return <Loading4 className="loading4 animate" />
              case 5:
                return <Loading5 className="loading5 animate" />
              case 6:
                return <Loading6 className="loading6 animate" />
              default:
                return
            }
          })}
          <Typography fontSize="12px" fontWeight={600} color="#FFFFFF" fontFamily={'Inter'}>
            LOADING
          </Typography>
        </Container>
      </PageContainerLayout>
    </Layout>
  )
}

const Container = styled(Box)(() => ({
  width: '100%',
  height: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .loading1': {
    position: 'absolute',
  },
  '& .loading2': {
    position: 'absolute',
  },
  '& .loading3': {
    position: 'absolute',
  },
  '& .loading4': {
    position: 'absolute',
  },
  '& .loading5': {
    position: 'absolute',
  },
  '& .loading6': {
    position: 'absolute',
  },
  '& .animate': {
    animationDuration: '0.75s',
    animationDelay: '0.5s',
    animationName: 'animate-fade',
    animationTimingFunction: 'cubic-bezier(.26,.53,.74,1.48)',
    animationFillMode: 'backwards',
  },
  '@keyframes animate-fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))

const Gradient = styled(Box)(() => ({
  background: 'radial-gradient(50% 64.61% at 50% 0%, #0E3112 0%, #0C0D2C 52.48%, #000000 100%)',
  position: 'absolute',
  width: '100%',
  height: '420px',
  left: '0',
  right: '0',
  margin: '0 auto',
  top: '0px',
  zIndex: 0,
}))
