import { createTheme } from '@mui/material/styles'
import { typography } from '../styles/typography'
import MuiButton from '@/styles/MuiButton'
import MuiAvatar from '@/styles/MuiAvatar'
import MuiTextField from '@/styles/MuiTextField'
import MuiAlert from '@/styles/MuiAlert'
import MuiCheckbox from '@/styles/MuiCheckbox'
import { colors } from '@/styles/colorScheme'

const theme = createTheme({
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton,
    MuiAvatar,
    MuiTextField,
    MuiAlert,
    MuiCheckbox,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          color: colors.white.primary,
          fontSize: '14px',
          fontWeight: 400,
          '&.MuiInputLabel-shrink': {
            display: 'none',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: 'rgba(255, 255, 255, 0.12)',
          borderRadius: '8px',
          color: 'white',
          width: '100%',
          '& .MuiAccordionSummary-content ': {
            margin: '0px',
            flexWrap: 'wrap',
            [theme.breakpoints.down('md')]: {
              padding: '5px',
              gap: '20px',
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '250px',
          },
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopCenter: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            top: '80px',
          },
        }),
        root: ({ theme }) => ({
          [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
            margin: '0 auto',
          },
        }),
      },
    },
  },
})
export default theme
